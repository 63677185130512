<!-- 年级数据编辑 -->
<template>
    <div class="editGradeData">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">年级数据编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单部分 -->
        <el-form ref="form" :model="form" id="form-1" :rules="rules" class="" inline label-width="150px">
            <el-form-item label="编号">
                <el-input v-model="form.number"  placeholder="请输入编号"></el-input>
            </el-form-item>
            <el-form-item label="年级名称" prop="gradeName">
                <el-input v-model="form.gradeName" placeholder="请输入年级名称"></el-input>
            </el-form-item>
            <el-form-item label="全称" prop="fullName">
                <el-input v-model="form.fullName" placeholder="请输入全称"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="condition">
               <el-select v-model="form.condition" placeholder="请选择状态" :clearable="true">
                   <el-option v-for="item in form.stateChoose" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="名称1">
                <el-input v-model="form.classOne" placeholder="请输入名称1"></el-input>
            </el-form-item>
            <el-form-item label="名称2">
                <el-input v-model="form.classTwo" placeholder="请输入名称2"></el-input>
            </el-form-item>
            <el-form-item label="说明">
                <el-input type="textarea" v-model="form.explain" class="textArea-width" placeholder="请输入说明"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;"  @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               form:{
                   number: "", //编号
                   name: "", //名称
                   abbreviation:"",//简称
                   location:"",//所在地区
                   classOne:"",//名称1
                   classTwo:"",//名称2
                   fullName: '',
                   gradeName: '',
                   stateChoose: [{
                           label: "可用",
                           value: "1"
                       },
                       {
                           label: "停用",
                           value: "2"
                       },
                   ], //状态选择
                   condition:"",//状态
                   explain:""//说明
               } ,
               rules:{
                    gradeName:[
                        { required: true, message: '请输入年级名称', trigger: 'blur' },
                    ],
                    fullName:[
                        { required: true, message: '请输入全称', trigger: 'blur' },
                    ]
               },
            }
        },
        created() {
            this.$request({url: '/api/grade/detail', method: 'POST', data: {id: this.$route.query.id}}).then(res => {
				console.log(res)
				var data = res.data.data
				var form = this.form
				form.number = data.number
				form.gradeName = data.grade_name
				form.fullName = data.all_name
				form.condition = data.status
				form.classOne = data.name1
				form.classTwo = data.name2
				form.explain = data.remark
			})
        },
        methods:{
            goBack() { //返回
               this.$router.go(-1);
            },
            submitForm(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        var data = this.form
                        this.$request({url: '/api/grade/edit', method: 'POST', data: {
                        	number: data.number,
                        	grade_name: data.gradeName,
                        	all_name: data.fullName,
                        	name1: data.classOne,
                        	name2: data.classTwo,
                        	status: data.condition,
                        	remark: data.explain,
							id: this.$route.query.id
                        }}).then(res => {
                        	if(res.code == 1){
                        		this.$message({
                        		  message: '修改列表数据成功',
                        		  type: 'success'
                        		})
                        		setTimeout(() => {
                        			this.goBack()	
                        		}, 1500)
                        	}else{
                                this.$message({
                                  message: res.msg,
                                  type: 'error'
                                })
                                setTimeout(() => {
                                	this.goBack()	
                                }, 1500)
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) {//取消
                this.goBack()
                this.$refs[form].resetFields();
            }
        }
    }
</script>

<style scoped="scoped">
    .editGradeData{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    .el-select {
        width: 300px;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
